var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useRef, useEffect } from 'react';
import { Button, Grid } from '@mui/material';
import Logs from './Logs';
export default function Console() {
    // Output state.
    var output = useRef("");
    var _a = useState(""), updated = _a[0], setUpdated = _a[1];
    // Serial state.
    var canUseSerial = useState(function () { return "serial" in navigator; })[0];
    var _b = useState("closed"), portState = _b[0], setPortState = _b[1];
    var portRef = useRef(null);
    var readerRef = useRef(null);
    var readerClosedPromiseRef = useRef(Promise.resolve());
    // Handles attaching the reader and disconnect listener when the port is open
    useEffect(function () {
        var _a;
        var port = portRef.current;
        if (portState === "open" && port) {
            // When the port is open, read until closed
            var aborted_1 = { current: false };
            (_a = readerRef.current) === null || _a === void 0 ? void 0 : _a.cancel();
            readerClosedPromiseRef.current.then(function () {
                if (!aborted_1.current) {
                    readerRef.current = null;
                    readerClosedPromiseRef.current = readUntilClosed(port);
                }
            });
            // Attach a listener for when the device is disconnected
            navigator.serial.addEventListener("disconnect", onPortDisconnect);
            return function () {
                aborted_1.current = true;
                navigator.serial.removeEventListener("disconnect", onPortDisconnect);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [portState]);
    function updateOutput(message) {
        output.current = output.current + message;
        setUpdated(message);
    }
    function clearOutput() {
        output.current = "";
        setUpdated("");
    }
    function downloadTxtFile() {
        var date = new Date().getTime();
        var element = document.createElement("a");
        var file = new Blob([output.current], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "tidbyt-diagnostics-".concat(date, ".txt");
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }
    /**
     * Reads from the given port until it's been closed.
     *
     * @param port the port to read from
     */
    function readUntilClosed(port) {
        return __awaiter(this, void 0, void 0, function () {
            var textDecoder, readableStreamClosed, _a, value, done, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!port.readable) return [3 /*break*/, 9];
                        textDecoder = new TextDecoderStream();
                        readableStreamClosed = port.readable.pipeTo(textDecoder.writable);
                        readerRef.current = textDecoder.readable.getReader();
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 5, 6, 7]);
                        _b.label = 2;
                    case 2:
                        if (!true) return [3 /*break*/, 4];
                        return [4 /*yield*/, readerRef.current.read()];
                    case 3:
                        _a = _b.sent(), value = _a.value, done = _a.done;
                        if (done) {
                            return [3 /*break*/, 4];
                        }
                        updateOutput(value);
                        return [3 /*break*/, 2];
                    case 4: return [3 /*break*/, 7];
                    case 5:
                        error_1 = _b.sent();
                        console.error(error_1);
                        return [3 /*break*/, 7];
                    case 6:
                        readerRef.current.releaseLock();
                        return [7 /*endfinally*/];
                    case 7: return [4 /*yield*/, readableStreamClosed.catch(function () { })];
                    case 8:
                        _b.sent(); // Ignore the error
                        _b.label = 9;
                    case 9: return [2 /*return*/];
                }
            });
        });
    }
    ;
    /**
     * Attempts to open the given port.
     */
    function openPort(port) {
        return __awaiter(this, void 0, void 0, function () {
            var error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, port.open({ baudRate: 115200 })];
                    case 1:
                        _a.sent();
                        portRef.current = port;
                        setPortState("open");
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        setPortState("closed");
                        console.error("Could not open port");
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    }
    function connectToPort() {
        return __awaiter(this, void 0, void 0, function () {
            var filters, port, error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(canUseSerial && portState === "closed")) return [3 /*break*/, 5];
                        // Clear output
                        clearOutput();
                        setPortState("opening");
                        filters = [
                            // Can identify the vendor and product IDs by plugging in the device and visiting: chrome://device-log/
                            // the IDs will be labeled `vid` and `pid`, respectively
                            {
                                usbVendorId: 0x10C4,
                                usbProductId: 0xEA60,
                            },
                        ];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, navigator.serial.requestPort({ filters: filters })];
                    case 2:
                        port = _a.sent();
                        return [4 /*yield*/, openPort(port)];
                    case 3:
                        _a.sent();
                        return [2 /*return*/, true];
                    case 4:
                        error_3 = _a.sent();
                        setPortState("closed");
                        console.error("User did not select port");
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/, false];
                }
            });
        });
    }
    ;
    function disconnectFromPort() {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var port;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(canUseSerial && portState === "open")) return [3 /*break*/, 3];
                        port = portRef.current;
                        if (!port) return [3 /*break*/, 3];
                        setPortState("closing");
                        // Cancel any reading from port
                        (_a = readerRef.current) === null || _a === void 0 ? void 0 : _a.cancel();
                        return [4 /*yield*/, readerClosedPromiseRef.current];
                    case 1:
                        _b.sent();
                        readerRef.current = null;
                        // Close and nullify the port
                        return [4 /*yield*/, port.close()];
                    case 2:
                        // Close and nullify the port
                        _b.sent();
                        portRef.current = null;
                        // Update port state
                        setPortState("closed");
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    }
    ;
    /**
     * Event handler for when the port is disconnected unexpectedly.
     */
    function onPortDisconnect() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: 
                    // Wait for the reader to finish it's current loop
                    return [4 /*yield*/, readerClosedPromiseRef.current];
                    case 1:
                        // Wait for the reader to finish it's current loop
                        _a.sent();
                        // Update state
                        readerRef.current = null;
                        readerClosedPromiseRef.current = Promise.resolve();
                        portRef.current = null;
                        setPortState("closed");
                        return [2 /*return*/];
                }
            });
        });
    }
    ;
    var primary = null;
    switch (portState) {
        case 'closed':
            primary = React.createElement(Button, { variant: "contained", onClick: function () { return connectToPort(); } }, "Connect");
            break;
        case 'closing':
            primary = React.createElement(Button, { disabled: true, variant: "contained", onClick: function () { return connectToPort(); } }, "Connect");
            break;
        case 'open':
            primary = React.createElement(Button, { variant: "contained", onClick: function () { return disconnectFromPort(); } }, "Disconnect");
            break;
        case 'opening':
            primary = React.createElement(Button, { disabled: true, variant: "contained", onClick: function () { return disconnectFromPort(); } }, "Disconnect");
            break;
    }
    var download = null;
    if (portState === 'closed' && output.current !== '') {
        download = React.createElement(Button, { sx: { marginLeft: 2 }, variant: "outlined", onClick: function () { return downloadTxtFile(); } }, "Download");
    }
    return (React.createElement(Grid, { container: true },
        React.createElement(Grid, { item: true, xs: 12 },
            primary,
            React.createElement(Button, { sx: { marginLeft: 2 }, variant: "outlined", onClick: function () { return clearOutput(); } }, "Reset"),
            download),
        React.createElement(Grid, { item: true, xs: 12, mt: 2 },
            React.createElement(Logs, { output: output.current }))));
}
